import AnamneseQuestionRepository from '@/shared/http/repositories/socialProject/anamnese-question'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import NewQuestions from '../components/questions.vue'
import QuestionModel from '@/shared/models/question'
import { IsSuccessCode } from '@/shared/utils/API'
import {toast, loading } from '@/shared/utils/UIHelper'


export default {
  name: 'EditarQuestão',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [NewQuestions.name]: NewQuestions
  },

  data: () => ({
    title: 'Questão',
    anamneseQuestion: new QuestionModel(),
    isNew: false
  }),

  provide() {
    return { parentValidator: this.$validator }
  },

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Questões',
            href: this.$router.resolve({ name: 'Questions' }).href
          },
          {
            text: 'Editar questão',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.getById()
  },

  methods: {
    getById() {
      loading.push()
      AnamneseQuestionRepository.GetById(this.$route.params.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
    
          this.anamneseQuestion = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar questão', 'ERRO')
        })
    },

    update(anamneseQuestion) {
      loading.push()
      AnamneseQuestionRepository.Update(anamneseQuestion)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
    
          loading.pop()
          toast.success('Questão editada com sucesso', 'EDITAR QUESTÃO')
          Promise.resolve()
          return this.$router.replace({ name: 'Questions' })
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao editar questão', 'ERRO')
        })
    }
  }
}
