import QuestionType from '@/shared/enums/questionType'

export default {
  name: 'NewQuestions',

  props: {
    placeholder: String,
    title: String,
    data: Object,
    isNew: Boolean
  },
  
  data: () => ({
    optionsQuestionType: Object.values(QuestionType)
  }),

  inject: [ 'parentValidator' ],

  created() {
    this.$validator = this.parentValidator
    this.$validator.reset()
  },

  methods: {
    backTo() {
      this.$router.go(-1)
    },

    save() {
      this.isNew 
        ? this.$emit('create', this.data)
        : this.$emit('update', this.data)
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
}